import React from "react"

export const SparkThinkLogo = props => (
  <svg
    width="500"
    height="112"
    viewBox="0 0 500 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.325 29.414C108.325 30.7931 109.436 31.9109 110.809 31.9109H114.456V0.00109863H108.325V29.414Z"
      fill="#FBFBFB"
    />
    <path
      d="M140.86 29.4097C140.86 30.7889 141.972 31.9046 143.344 31.9046H146.99V0H140.86V29.4097Z"
      fill="#FBFBFB"
    />
    <path
      d="M201.297 8.41138C198.317 8.41138 195.333 9.42634 193.572 12.3306C192.045 9.89971 189.343 8.41138 186.055 8.41138C183.473 8.41138 180.984 9.28464 179.363 11.7176V9.02644H173.396V29.4096C173.396 30.7888 174.509 31.9045 175.88 31.9045H179.526V19.3671C179.526 15.9202 181.711 14.1968 184.342 14.1968C187.136 14.1968 188.781 16.0126 188.781 19.3198V29.4096C188.781 30.7888 189.892 31.9045 191.264 31.9045H194.907V19.3671C194.907 15.9202 197.095 14.1968 199.723 14.1968C202.494 14.1968 204.161 16.0126 204.161 19.3198V29.4096C204.161 30.7888 205.278 31.9045 206.643 31.9045H210.268V17.3372C210.268 12.023 206.649 8.41138 201.297 8.41138Z"
      fill="#FBFBFB"
    />
    <path
      d="M98.8397 17.9607L96.0692 17.6038C94.0064 17.2617 92.6911 16.9069 92.714 15.5823C92.7349 14.3301 93.8143 13.5219 96.1402 13.5618C98.4149 13.6028 100.694 14.242 102.482 14.9389C103.717 15.4207 105.107 14.8696 105.656 13.6531L106.789 11.1467C103.782 9.60275 100.346 8.52901 96.2279 8.45659C90.8788 8.36423 86.4098 10.8455 86.3284 15.5771C86.2637 19.2727 87.9266 21.8148 94.5931 22.8749L95.946 23.067C98.9013 23.4732 101.094 23.6086 101.064 25.2365C101.036 26.8928 99.2792 27.4742 95.9011 27.4155C92.4677 27.3535 89.3495 25.7319 87.8598 24.8996L85.725 29.6721C88.8881 31.5929 92.7078 32.4892 95.8103 32.5448C103.471 32.6792 107.32 29.966 107.401 25.2008C107.476 20.909 103.78 18.5495 98.8397 17.9607Z"
      fill="#FBFBFB"
    />
    <path
      d="M133.266 9.02449V11.5519C131.762 9.68573 129.578 8.40942 126.452 8.40942C120.181 8.40942 115.437 13.5829 115.437 20.4546C115.437 27.3263 120.181 32.4987 126.452 32.4987C129.578 32.4987 131.784 31.2256 133.266 29.3825V29.415C133.266 30.791 134.375 31.9078 135.744 31.9078H139.254V9.02449H133.266ZM127.581 26.7606C123.988 26.7606 121.709 23.9498 121.709 20.4546C121.709 16.9594 123.988 14.1465 127.581 14.1465C131.082 14.1465 133.453 16.8408 133.453 20.4546C133.453 24.0694 131.082 26.7606 127.581 26.7606Z"
      fill="#FBFBFB"
    />
    <path
      d="M160.24 8.40942C153.168 8.40942 147.955 13.5587 147.955 20.4546C147.955 27.3494 153.168 32.4987 160.24 32.4987C167.331 32.4987 172.572 27.3494 172.572 20.4546C172.572 13.5587 167.331 8.40942 160.24 8.40942ZM160.24 26.7606C156.786 26.7606 154.204 24.1628 154.204 20.4546C154.204 16.7464 156.786 14.1465 160.24 14.1465C163.692 14.1465 166.298 16.7464 166.298 20.4546C166.298 24.1628 163.692 26.7606 160.24 26.7606Z"
      fill="#FBFBFB"
    />
    <path
      d="M404.709 41.0288C408.24 41.0151 411.089 38.1245 411.076 34.5748C411.06 31.0272 408.187 28.1618 404.658 28.1744C401.127 28.1891 398.278 31.0796 398.291 34.6283C398.305 38.176 401.178 41.0424 404.709 41.0288Z"
      fill="#FBFBFB"
    />
    <path
      d="M399.078 84.4842C399.078 86.9812 401.091 89.0048 403.574 89.0048H410.29V47.1395H399.079V84.4842H399.078Z"
      fill="#FBFBFB"
    />
    <path
      d="M295.763 66.8425L312.198 47.1175H298.431L286.686 62.0417H282.727V26.7427H271.498V68.2343H271.488V84.524C271.488 87.0189 273.501 89.0447 275.984 89.0447H282.738V72.079H286.513L298.516 89.0342H312.327L295.763 66.8425Z"
      fill="#FBFBFB"
    />
    <path
      d="M235.611 47.1175H224.64V51.7462C221.886 48.3287 217.885 45.9923 212.162 45.9923C200.676 45.9923 191.984 55.467 191.984 68.0538C191.984 80.6427 200.676 90.1163 212.162 90.1163C217.877 90.1163 221.916 87.7852 224.627 84.4212V84.5251C224.627 87.0189 226.639 89.0447 229.123 89.0447H235.612L235.611 47.1175ZM214.229 79.6035C207.646 79.6035 203.473 74.4563 203.473 68.0538C203.473 61.6512 207.646 56.504 214.229 56.504C220.64 56.504 224.985 61.4361 224.985 68.0538C224.985 74.6736 220.64 79.6035 214.229 79.6035Z"
      fill="#FBFBFB"
    />
    <path
      d="M170.342 45.9923C164.619 45.9923 160.618 48.3287 157.908 51.7462V47.1175H146.937V101.817C146.937 104.312 148.949 106.338 151.432 106.338H158.123V84.6658C160.833 87.9111 164.791 90.1163 170.386 90.1163C181.872 90.1163 190.564 80.6437 190.564 68.0538C190.564 55.467 181.872 45.9923 170.342 45.9923ZM168.277 79.6035C161.909 79.6035 157.563 74.6736 157.563 68.0538C157.563 61.4361 161.909 56.504 168.277 56.504C174.903 56.504 179.033 61.6512 179.033 68.0538C179.033 74.4563 174.903 79.6035 168.277 79.6035Z"
      fill="#FBFBFB"
    />
    <path
      d="M263.149 45.9923C258.03 45.9923 254.029 47.7651 251.403 51.7032V47.1175H240.518V84.5241C240.518 87.0189 242.531 89.0447 245.015 89.0447H251.748V66.1949C251.748 59.9656 255.319 56.7654 260.524 56.7654C262.805 56.7654 265.472 57.2839 267.322 58.1057L268.999 46.9002C267.451 46.3387 265.558 45.9923 263.149 45.9923Z"
      fill="#FBFBFB"
    />
    <path
      d="M129.276 63.3599L124.202 62.7081C120.423 62.0805 118.012 61.4329 118.053 59.0062C118.094 56.7097 120.069 55.2298 124.332 55.3043C128.499 55.3788 132.674 56.5491 135.953 57.8254C138.215 58.7092 140.764 57.6942 141.769 55.4712L143.844 50.8739C138.334 48.0463 132.037 46.0783 124.493 45.945C114.687 45.775 106.501 50.3239 106.35 58.9936C106.232 65.7688 109.281 70.4269 121.496 72.3655L123.972 72.7181C129.39 73.4644 133.407 73.71 133.355 76.6909C133.302 79.7295 130.084 80.7927 123.891 80.6846C117.6 80.5744 111.891 77.6009 109.159 76.0779L105.247 84.821C111.044 88.3424 118.042 89.983 123.727 90.0837C137.763 90.3314 144.818 85.3605 144.967 76.63C145.103 68.7633 138.331 64.4337 129.276 63.3599Z"
      fill="#FBFBFB"
    />
    <path
      d="M378.283 45.9923C373.723 45.9923 369.292 47.4639 366.323 51.6171V26.6997H355.094L355.083 84.4853C355.083 86.9823 357.096 89.0059 359.579 89.0059H366.332L366.323 66.0648C366.323 59.7505 370.324 56.5922 375.142 56.5922C380.261 56.5922 383.274 59.9236 383.274 65.9788L383.254 84.4863C383.254 86.9833 385.267 89.0069 387.751 89.0069H394.504V62.3472C394.504 52.6122 387.748 45.9923 378.283 45.9923Z"
      fill="#FBFBFB"
    />
    <path
      d="M483.435 66.8425L499.871 47.1174H486.103L474.358 62.0417H470.4V26.7427H459.17V79.9803L459.165 84.4485C459.165 86.9434 461.178 88.9691 463.661 88.9691H470.4V72.079H474.186L486.189 89.0342H500L483.435 66.8425Z"
      fill="#FBFBFB"
    />
    <path
      d="M347.607 76.8787C345.326 78.2621 342.443 79.6035 339.776 79.6035C336.291 79.6035 333.537 77.7016 333.537 72.4652V57.1537H348.639V47.1174H333.537V34.6587H322.307V47.1111H314.258V52.6393C314.258 55.1342 316.27 57.1599 318.754 57.1599H322.307V73.3752C322.307 84.2322 327.599 90.1152 337.925 90.1152C343.863 90.1152 348.08 88.2112 351.735 85.7037L347.607 76.8787Z"
      fill="#FBFBFB"
    />
    <path
      d="M438.389 45.9923C433.657 45.9923 429.096 47.5929 426.127 52.0495V47.1175H415.199V84.4474C415.199 86.9423 417.212 88.968 419.696 88.968H426.429V66.0637C426.429 59.7494 430.43 56.5911 435.248 56.5911C440.368 56.5911 443.38 59.9226 443.38 65.9777V69.1076L443.376 84.4474C443.376 86.9423 445.388 88.968 447.872 88.968H454.609V62.345C454.609 52.6121 447.854 45.9923 438.389 45.9923Z"
      fill="#FBFBFB"
    />
    <path
      d="M73.4256 60.6436C75.6293 59.805 76.7442 57.3321 75.9132 55.1122C75.0812 52.8944 72.6176 51.7724 70.4107 52.6079C68.2049 53.4465 67.09 55.9204 67.9231 58.1414C68.753 60.3571 71.2166 61.4791 73.4256 60.6436Z"
      fill="#FBFBFB"
    />
    <path
      d="M88.3181 50.41C87.8337 49.1201 86.4035 48.4683 85.1216 48.9553C83.8386 49.4381 83.1914 50.8792 83.6747 52.1702C84.1581 53.4591 85.5903 54.1088 86.8723 53.625C88.1531 53.1401 88.8014 51.699 88.3181 50.41Z"
      fill="#FBFBFB"
    />
    <path
      d="M15.0548 73.5871C12.848 74.4237 11.7341 76.8997 12.564 79.1196C13.3971 81.3395 15.8607 82.4583 18.0686 81.6218C20.2734 80.7853 21.3893 78.3103 20.5573 76.0904C19.7253 73.8716 17.2606 72.7496 15.0548 73.5871Z"
      fill="#FBFBFB"
    />
    <path
      d="M1.60602 80.606C0.324088 81.0951 -0.32314 82.532 0.160195 83.8199C0.644573 85.1098 2.07474 85.7627 3.35772 85.2757C4.63861 84.7918 5.28479 83.3507 4.8025 82.0607C4.31917 80.7729 2.889 80.1221 1.60602 80.606Z"
      fill="#FBFBFB"
    />
    <path
      d="M58.6666 93.4257C57.8346 91.209 55.372 90.0901 53.1641 90.9256C50.9573 91.7642 49.8434 94.2381 50.6744 96.4569C51.5074 98.6779 53.9711 99.7989 56.1769 98.9613C58.3837 98.1237 59.4997 95.6477 58.6666 93.4257Z"
      fill="#FBFBFB"
    />
    <path
      d="M59.1051 106.765C57.8221 107.25 57.1759 108.692 57.6572 109.981C58.1405 111.269 59.5727 111.92 60.8547 111.434C62.1356 110.949 62.7828 109.511 62.3026 108.221C61.8182 106.931 60.3849 106.28 59.1051 106.765Z"
      fill="#FBFBFB"
    />
    <path
      d="M47.956 55.678C48.7765 55.9614 49.5647 56.3319 50.3069 56.7853C47.8464 58.9212 46.8296 62.4752 48.0301 65.6764C49.1889 68.7727 52.1808 70.8541 55.4743 70.8541H55.5328C55.2739 71.6665 54.9294 72.4505 54.5056 73.1905C53.0243 71.4534 50.8477 70.4133 48.4707 70.4133C47.5134 70.4133 46.5718 70.5864 45.6677 70.9276C42.3794 72.1745 40.3907 75.3904 40.5223 78.7366C39.6329 78.4647 38.78 78.0921 37.9782 77.6219C38.947 76.8441 39.7258 75.8407 40.2519 74.6704C41.1277 72.7234 41.1966 70.5487 40.4482 68.5513C39.2873 65.4529 36.2944 63.3694 33.0019 63.3694C32.8641 63.3694 32.7273 63.3726 32.5906 63.3799C32.8839 62.4783 33.2848 61.6113 33.7838 60.799C35.2714 62.6662 37.5555 63.8197 40.0066 63.8197C40.9649 63.8197 41.9076 63.6465 42.8105 63.3043C46.0289 62.0858 48.0072 58.9601 47.956 55.678Z"
      fill="#FBFBFB"
    />
    <path
      d="M29.8096 40.802C30.6426 43.0198 33.1052 44.1418 35.3131 43.3064C37.521 42.4688 38.6338 39.9928 37.8018 37.7718C36.9698 35.5582 34.5072 34.4352 32.3014 35.2717C30.0946 36.1061 28.9797 38.5842 29.8096 40.802Z"
      fill="#FBFBFB"
    />
    <path
      d="M29.3743 27.4638C30.6551 26.9789 31.3013 25.5388 30.819 24.251C30.3368 22.9631 28.9055 22.3092 27.6236 22.7973C26.3406 23.2854 25.6944 24.7212 26.1767 26.0122C26.6611 27.299 28.0913 27.9487 29.3743 27.4638Z"
      fill="#FBFBFB"
    />
    <path
      d="M64.0731 71.1743C61.7681 70.1247 59.0518 71.1554 58.0089 73.4708C56.965 75.7925 57.9902 78.5214 60.2941 79.57C62.6001 80.6185 65.3153 79.5899 66.3582 77.2735C67.4021 74.9518 66.3791 72.226 64.0731 71.1743Z"
      fill="#FBFBFB"
    />
    <path
      d="M75.4121 78.0113C73.8723 77.3113 72.058 77.9987 71.3617 79.5479C70.6644 81.0992 71.3492 82.9192 72.8879 83.6235C74.4277 84.3205 76.242 83.634 76.9404 82.0869C77.6357 80.5356 76.9529 78.7114 75.4121 78.0113Z"
      fill="#FBFBFB"
    />
    <path
      d="M84.2207 83.4346C83.3271 83.0294 82.2728 83.4283 81.8667 84.3288C81.4606 85.2283 81.8583 86.2853 82.7551 86.6936C83.6476 87.105 84.7009 86.702 85.108 85.8025C85.512 84.9009 85.1143 83.8429 84.2207 83.4346Z"
      fill="#FBFBFB"
    />
    <path
      d="M30.1415 60.7003C31.1834 58.3828 30.1614 55.6517 27.8554 54.6032C25.5493 53.5515 22.8352 54.5832 21.7912 56.9007C20.7473 59.2182 21.7704 61.9514 24.0764 62.9999C26.3813 64.0495 29.0966 63.0188 30.1415 60.7003Z"
      fill="#FBFBFB"
    />
    <path
      d="M12.7373 56.1618C14.2771 56.863 16.0914 56.1744 16.7877 54.6294C17.484 53.0771 16.8023 51.2518 15.2605 50.5528C13.7207 49.8538 11.9064 50.5402 11.2111 52.0873C10.5138 53.6365 11.1955 55.4607 12.7373 56.1618Z"
      fill="#FBFBFB"
    />
    <path
      d="M5.3944 47.4786C4.49976 47.0734 3.44853 47.4744 3.04141 48.3728C2.63636 49.2713 3.03201 50.3314 3.92874 50.7386C4.82338 51.1448 5.87669 50.7481 6.28278 49.8475C6.68677 48.9459 6.29009 47.8869 5.3944 47.4786Z"
      fill="#FBFBFB"
    />
    <path
      d="M37.9093 81.1769C35.6044 80.1273 32.8891 81.158 31.8442 83.4745C30.8034 85.7941 31.8264 88.5241 34.1335 89.5737C36.4374 90.6233 39.1527 89.5916 40.1945 87.2772C41.2384 84.9555 40.2154 82.2297 37.9093 81.1769Z"
      fill="#FBFBFB"
    />
    <path
      d="M31.8692 94.6034C30.3253 93.8991 28.5141 94.5877 27.8188 96.1369C27.1194 97.6882 27.8032 99.5124 29.3461 100.214C30.8859 100.911 32.6971 100.223 33.3965 98.6759C34.0917 97.1287 33.409 95.3024 31.8692 94.6034Z"
      fill="#FBFBFB"
    />
    <path
      d="M27.1142 105.168C26.2216 104.758 25.1652 105.158 24.7591 106.061C24.3551 106.959 24.7518 108.017 25.6464 108.426C26.5421 108.833 27.5965 108.434 28.0026 107.533C28.4055 106.633 28.0088 105.574 27.1142 105.168Z"
      fill="#FBFBFB"
    />
    <path
      d="M50.4499 53.3132C52.756 54.3639 55.4722 53.3342 56.5162 51.0146C57.559 48.6982 56.5349 45.9671 54.2289 44.9175C51.924 43.869 49.2066 44.8944 48.1658 47.2151C47.1209 49.5326 48.1439 52.2636 50.4499 53.3132Z"
      fill="#FBFBFB"
    />
    <path
      d="M56.4921 39.89C58.0319 40.589 59.8463 39.9026 60.5436 38.3544C61.2409 36.8063 60.5572 34.98 59.0153 34.2799C57.4755 33.5788 55.6622 34.2663 54.9659 35.8155C54.2676 37.3647 54.9524 39.1868 56.4921 39.89Z"
      fill="#FBFBFB"
    />
    <path
      d="M61.2461 29.3256C62.1408 29.7329 63.1951 29.334 63.6002 28.4314C64.0052 27.535 63.6096 26.4728 62.7108 26.0645C61.8172 25.6604 60.7639 26.0603 60.3578 26.9588C59.9538 27.8573 60.3515 28.9194 61.2461 29.3256Z"
      fill="#FBFBFB"
    />
    <path
      d="M54.277 56.441C50.9719 57.6942 49.3048 61.4003 50.5502 64.7213C51.7935 68.0443 55.4806 69.7215 58.7856 68.4694C62.0886 67.2183 63.7568 63.51 62.5135 60.1881C61.2681 56.8692 57.5799 55.1878 54.277 56.441Z"
      fill="#FBFBFB"
    />
    <path
      d="M29.6927 65.7615C26.3897 67.0126 24.7205 70.7208 25.9669 74.0449C27.2113 77.3637 30.8984 79.0431 34.2024 77.7888C37.5043 76.5387 39.1735 72.8273 37.9292 69.5075C36.6838 66.1844 32.9967 64.5082 29.6927 65.7615Z"
      fill="#FBFBFB"
    />
    <path
      d="M54.8563 77.2073C53.6109 73.8854 49.9238 72.2113 46.6208 73.4634C43.3168 74.7135 41.6487 78.4207 42.8941 81.7437C44.1374 85.0625 47.8255 86.7408 51.1285 85.4866C54.4314 84.2365 56.0996 80.5293 54.8563 77.2073Z"
      fill="#FBFBFB"
    />
    <path
      d="M33.623 57.0215C34.8684 60.3414 38.5566 62.0228 41.8595 60.7707C45.1625 59.5164 46.8296 55.8082 45.5842 52.4851C44.3409 49.1642 40.6538 47.4901 37.3508 48.737C34.0458 49.9955 32.3776 53.7016 33.623 57.0215Z"
      fill="#FBFBFB"
    />
  </svg>
)
