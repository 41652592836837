import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SafeArea } from "./SafeArea"
import { Flex } from "reflexbox/styled-components"
// Logo
import { SparkThinkLogo } from "./sparkthink-logo--full"

const Root = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
  background-color: transparent;
  padding: 5px 0;
  z-index: 999;
  display: inline-block;
`

const LogoContianer = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 270px;
  
  ${props => `
    width: 100%;
    max-width: 140px;
    
    @media screen and (min-width: 40em) {    
      max-width: 270px;
    }
  `}

  svg {
    width: 100%;
    height: auto;
`

const NavContainer = styled.ul`
  display: inline-block;
  position: absolute;
  position: relative;
  display: flex;
  right: 0;

  & li {
    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }
`

const NavItem = styled.div`
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  padding-bottom: 8px;
`

const LaunchButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border-radius: 2px;
  border-color: #fff;
  background-color: #fff;
  color: ${props => props.theme.colors.primary[500]};
  cursor: pointer;
  transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  }
`

interface IHeaderProps {
  showLaunchButton?: boolean
}

export const Header: React.FC<IHeaderProps> = props => {
  const { showLaunchButton = true } = props

  return (
    <Root>
      <SafeArea>
        <Flex justifyContent="space-between" alignItems="center">
          <LogoContianer>
            <SparkThinkLogo />
          </LogoContianer>
          <NavContainer>
            <li>
              <NavItem>
                {showLaunchButton && (
                  <a href="/admin">
                    <LaunchButton>Launch SparkThink</LaunchButton>
                  </a>
                )}
              </NavItem>
            </li>
          </NavContainer>
        </Flex>
      </SafeArea>
    </Root>
  )
}

export default Header
