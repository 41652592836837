/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Header } from "./header"
import { Flex } from "reflexbox/styled-components"

import { BlackwingProvidor, defaultTheme } from "@slalom/blackwing"

import styled from "styled-components"

const Main = styled(Flex)`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

interface ILayoutProps {
  showHeaderLaunchButton?: boolean
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  showHeaderLaunchButton,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = { ...defaultTheme }
  theme.colors.modes = {}

  return (
    <BlackwingProvidor theme={theme}>
      <Main flexDirection="column">
        <Header showLaunchButton={showHeaderLaunchButton} />
        {children}
      </Main>
    </BlackwingProvidor>
  )
}

export default Layout
