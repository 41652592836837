import styled  from "styled-components"

export const SafeArea = styled.div`
  position: relative;
  margin: 0 auto;
  ${props => `
    width: 100%;
    padding: 0px 15px;

    @media screen and (min-width: 40em) {
        width: 40em;
    }
    @media screen and (min-width: 52em) {
        width: 52em;
    }
    @media screen and (min-width: 64em) {
        width: 70em;
    }

    @media screen and (min-width: 100em) {
        width: 100em;
    }
`}
`
